<!--index:Hi, 足迹 2024/07/03 22:51:52 YuAng-->

<template>
  <div class="history-index">
    <feed-list-common v-for="n in 10" :key="'post' + n">
    </feed-list-common>
  </div>
</template>
<script>
import FeedListCommon from '@/views/components/FeedListCommon'
export default {
  name: 'index',
  components: { FeedListCommon },
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      tip: 'Hi, 足迹'
    }
  },
  created () {
    // 请求接口
  },
  mounted () {
    // 更新Dom
  },
  methods: {
    xxxAction: function (action) {
      console.log(action)
    }
  }
}
</script>

<style scoped>
.xx-index {
  color: brown;
}
</style>
