<!--HeadCommon:Hi, HeadCommon 2024/07/14 11:38:30 YuAng-->

<template>
  <div @click.stop="jumpUser" class="user-title-area">
    <head-img :user="item" border></head-img>
    <span class="user-name">{{ item.name }}</span>
    <slot></slot>
  </div>
</template>
<script>
import HeadImg from './HeadImg.vue'
export default {
  components: { HeadImg },
  name: 'HeadCommon',
  props: {
    item: {
      type: Object,
      default: function () {
        return {
          name: '攀岩大志',
          id: 19377785
        }
      }
    }
  },
  methods: {
    jumpUser: function () {
      const query = {}
      if (this.$store.state.user) {
        if (this.item.id !== this.$store.state.user.id) {
          query.id = this.item.id
        }
      } else {
        query.id = this.item.id
      }
      this.$router.push({ path: '/user/yingdi', query })
    }
  }
}
</script>

<style scoped>
.user-title-area {
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-name {
  font-weight: 700;
  font-size: 16px;
  margin-left: 10px;
}
</style>
