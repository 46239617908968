<!--Tag:Hi, Tag 2024/07/13 22:50:32 YuAng-->

<template>
  <div class="xx-tag" @click.stop="$router.push('/tag/' + item.id)">
    <i class="el-icon-price-tag"></i>
    <span>{{item.name}}</span>
  </div>
</template>
<script>
export default {
  name: 'Tag',
  props: {
    item: {
      type: Object,
      default: function () {
        return {
          id: 1,
          name: '炉石传说'
        }
      }
    }
  }
}
</script>

<style scoped>
.xx-tag {
  background-color: rgb(249, 249, 249);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 2px 6px;
  text-align: center;
  color: rgb(155, 155, 155);
  margin-right: 8px;
  cursor: pointer;
  border-radius: 6px;
}

.el-icon-price-tag {
  color: rgb(59, 130, 246);
  margin-right: 4px;
  font-weight: bolder;
}
</style>
